<template>
  <div id="container" v-if="fetch_data">
    <section id="shop">
      <div class="wrap">
        <div class="register_step flex">
          <div class="step_box flex on">
            <div class="num">1</div>
            <p>お届け先の<br class="sp">入力</p>
          </div>
          <div class="step_box flex on">
            <div class="num">2</div>
            <p>ご注文内容の<br class="sp">確認</p>
          </div>
          <div class="step_box flex">
            <div class="num">3</div>
            <p>決済情報の<br class="sp">入力</p>
          </div>
          <div class="step_box flex">
            <div class="num">4</div>
            <p>ご注文を<br class="sp">受け付けました</p>
          </div>
        </div>

        <div class="cts_box">
          <h2 class="sub_ttl"><span>クーポン・ポイントのご利用</span></h2>

          <div class="mb40" v-if="session">
            <h3 class="min_ttl">ご購入者</h3>
            <div class="df_table">
              <dl class="flex">
                <dt>おなまえ</dt>
                <dd>{{ session.last_name }} {{ session.first_name }} （{{ session.last_name_kana }}
                  {{ session.first_name_kana }}）
                </dd>
              </dl>
              <dl class="flex">
                <dt>連絡先メールアドレス</dt>
                <dd>{{ session.email }}</dd>
              </dl>
              <dl class="flex">
                <dt>連絡先電話番号</dt>
                <dd>{{ session.phone_number }}</dd>
              </dl>
              <dl class="flex">
                <dt>郵便番号</dt>
                <dd>〒{{ session.zip }}</dd>
              </dl>
              <dl class="flex">
                <dt>住所</dt>
                <dd>{{ session.prefecture }} {{ session.address1 }} {{ session.address2 }}<br>{{ session.address3 }}
                </dd>
              </dl>
            </div>
          </div>

          <h3 class="min_ttl">商品のお届け先</h3>
          <div class="df_table">
            <dl class="flex">
              <dt>おなまえ</dt>
              <dd>{{ shopping.last_name }} {{ shopping.first_name }} （{{ shopping.last_name_kana }}
                {{ shopping.first_name_kana }}）
              </dd>
            </dl>
            <dl class="flex">
              <dt>郵便番号</dt>
              <dd>〒{{ shopping.zip }}</dd>
            </dl>
            <dl class="flex">
              <dt>住所</dt>
              <dd>{{ shopping.prefecture }} {{ shopping.address1 }} {{ shopping.address2 }}<br>{{ shopping.address3 }}
              </dd>
            </dl>
          </div>
        </div>

        <div class="cts_box form">
          <h3 class="min_ttl">ご注文内容</h3>
          <div class="cart_box">
            <div class="cart_item flex" v-for="article in fetch_data.shopping.articles"
                 v-bind:key="article.article.id">
              <div class="image"><img v-bind:src="article.article.image_files[0]" alt=""></div>
              <div class="cart_cts">
                <h3 class="item_ttl">{{ article.article.title }}</h3>
                <p class="price">¥ {{ delimited(article.price) }}</p>
                <template v-if="false">
                  <div class="tagbox" v-if="article.article.point_usable"><span class="tag">ポイント払い可能</span></div>
                  <div class="tagbox" v-else><span class="tag">ポイント払い不可</span></div>
                </template>
                <div class="point">ポイント：<span>3%</span>（ポイント利用分には付与されません）</div>
                <div class="item_count flex">
                  <p>数量: {{ article.count }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="cart_all flex">
            <div class="count_all">カートの合計（{{ delimited(fetch_data.shopping_count) }}点の商品）（税込）：</div>
            <div class="price_all">¥ {{ delimited(fetch_data.total_price) }}</div>
          </div>
        </div>

        <div class="cts_box" v-if="fetch_data.usable_coupons && fetch_data.usable_coupons.length > 0">
          <h3 class="min_ttl">利用可能なクーポンがあります</h3>
          <p class="lead_txt">
            現在以下のクーポンが利用できます。クーポンを選んで「クーポンを利用する」ボタンを押してください。<br>
            （複数のクーポンを同時に使用することはできません）
          </p>
          <div class="scrl_box coupon workshop">
            <div class="inner">
              <ul class="coupon_list">
                <li class="flex" v-for="coupon in fetch_data.usable_coupons" v-bind:key="`coupon-${coupon.id}`">
                  <div class="thumb"><img v-bind:src="coupon.image_file_url" alt=""></div>
                  <div class="detail">
                    <div class="head flex">
                      <h3>{{ coupon.title }}</h3>
                      <div class="dead_line">有効期限：<span>{{ moment(coupon.close_date, 'YYYY年MM月DD日') }}</span>
                      </div>
                    </div>
                    <p>{{ coupon.description }}</p>
                  </div>
                  <div class="btn_wrap">
                    <a href="javascript:void(0);" v-if="!coupon.use_coupon" @click="useCouponClicked(coupon, true)"
                       class="btn">クーポンを利用する</a>
                    <a href="javascript:void(0);" v-else @click="useCouponClicked(coupon, false)"
                       class="btn active">クーポン適用中</a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div v-bind:class="{ 'point-disabled': true }" v-show="isUserSignedIn()">
          <div class="cts_box claim">
            <h3 class="min_ttl">ポイントの利用</h3>
            <p class="lead_txt">
              ポイントを利用する場合は、ポイント数を入力の上「ポイントを利用する」ボタンを押してください。
            </p>
            <table class=""><!-- ポイント利用できない場合はclass="point_none" を追加 -->
              <tbody>
              <tr>
                <td class="date">現在のポイント数</td>
                <td class="price">{{ delimited(fetch_data.total_points) }} <span class="sml">ポイント</span></td>
                <td class="point flex">
                  <h3 class="sp">ポイント利用額（1ポイント＝1円）</h3>
                  <p>ご利用ポイントを入力</p>
                  <div class="point_input">
                    <input type="text" v-model="use_points"
                           @input="use_points = replaceFullToNumber(use_points)"
                           v-bind:disabled="fetch_data.total_points === 0"
                           maxlength="6" class="point">
                    <!--<input type="text" value="1000" maxlength="5" class="point" disabled> ポイント利用できない場合-->
                    <p>{{ delimited(calc_usable_points) }}ポイントまで利用可能</p>
                  </div>
                  <p class="bold">ポイント</p>
                </td>
              </tr>
              <tr>
                <td colspan="3" class="btn">
                  <button type="button" class="submit light skeleton"
                          v-bind:disabled="fetch_data.total_points === 0"
                          @click="usePointClicked()">ポイントを利用する
                  </button>
                </td>
                <!--<td colspan="3" class="btn"><input type="submit" class="submit light skeleton" value="現在ポイントの利用はできません" disabled></td> ポイント利用できない場合-->
              </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="cts_box mt40">
          <h3 class="min_ttl">お会計内容</h3>
          <p class="lead_txt">お買い上げ商品の合計額を表示しています。</p>
          <div class="register_box">
            <dl class="flex">
              <dt>お買い上げ商品の合計金額</dt>
              <dd class="item"></dd>
              <dd class="price">¥ {{ delimited(fetch_data.total_price) }}</dd>
            </dl>
            <dl class="flex">
              <dt>送料</dt>
              <dd class="item"></dd>
              <dd class="price">¥ {{ delimited(fetch_data.postage_price) }}</dd>
            </dl>
            <dl class="flex">
              <dt>合計金額</dt>
              <dd class="item"></dd>
              <dd class="price">¥ {{ delimited(fetch_data.total_price + fetch_data.postage_price) }}</dd>
            </dl>
            <dl class="flex" v-if="useCoupons().length > 0">
              <dt>クーポンのご利用</dt>
              <dd class="item"></dd>
              <dd class="price">
                <div v-for="coupon in useCoupons()" v-bind:key="`use-coupon-${coupon.id}`">
                  -¥ {{ delimited(coupon.price * coupon.count) }}<span>{{ coupon.title }}</span>
                </div>
              </dd>
            </dl>
            <dl class="flex" v-if="calc_use_points">
              <dt>ポイントのご利用</dt>
              <dd class="item"></dd>
              <dd class="price">-¥ {{ delimited(calc_use_points) }}</dd>
            </dl>
            <dl class="flex txtR">
              <dt>クーポン・ポイント適用後の金額（税込）<span class="pc">：</span></dt>
              <dd class="price">¥ {{ delimited(calc_total_price) }}</dd>
            </dl>
            <dl class="flex txtR total">
              <dt class="bold">お会計合計<span class="pc">：</span></dt>
              <dd class="price">¥ {{ delimited(calc_total_price) }}</dd>
            </dl>
          </div>
          <div class="mt10 alnR">
          ご注文後のポイント付与額は<a href="https://www.millioneyes.jp/customer/point" target="_blank">ポイント通帳</a>で確認できます
          </div>
        </div>

        <div class="cts_box" v-if="calc_total_price === 0">
          <div class="btnBox">
            <p class="alnC mb10">内容に変更なければ<br class="sp">この内容で商品購入のお手続きを行います。</p>
            <a href="javascript:void(0)" @click="paymentClicked()" class="submit light arrow">商品を購入する</a>
          </div>
        </div>

        <div class="cts_box" v-else>
          <h3 class="min_ttl">決済方法</h3>
          <p class="lead_txt">決済方法を選択してください。</p>
          <form action="">
            <div class="radio">
              <input id="radio-1" type="radio" name="payment" v-model="payment_type" value="payment_type_card">
              <label for="radio-1">クレジット決済</label>
              <input id="radio-2" type="radio" name="payment" v-model="payment_type" value="payment_type_bank">
              <label for="radio-2">銀行振り込み</label>
            </div>
          </form>
          <div class="btnBox" v-if="payment_type === 'payment_type_card'">
            <p class="alnC mb10">内容に変更なければ<br class="sp">続いて代金の決済を行います。</p>
            <a href="javascript:void(0)" @click="paymentClicked()" class="submit light arrow">決済情報を入力する</a>
            <div class="paygent flex">
              <img src="/common/images/paygent_logo.png" alt="PAYGENT">
              <p>
                クレジットカード決済サービス会社（PAYGENT）の決済画面に移動します。PAYGENTは、NTTデータと三菱UFJニコスの合弁による国内大手決済サービス会社です。
              </p>
            </div>
          </div>
          <div class="btnBox mt40" v-else>
            <a href="javascript:void(0)" @click="paymentClicked()" class="submit light arrow">銀行振込みで決済する</a>
          </div>
        </div>

        <div class="cts_box">
          <div class="btnBox">
            <p class="alnC mb10">入力内容を変更する場合は<br class="sp">こちらのボタンを押してください。</p>
            <a @click="$router.go(-1)" class="submit light skeleton arrow back">前のページにもどる</a>
          </div>
        </div>

      </div>
    </section>
  </div>
</template>

<script>

import Libraries from '@/components/Libraries'

export default {
  mixins: [Libraries],
  data() {
    return {
      fetch_data: null,
      request_exec: false,
      shopping: null,
      session: null,
      use_points: null,
      usable_points: null,

      calc_use_points: null,
      calc_total_coupon: null,
      calc_total_price: null,
      calc_usable_points: null,
      use_coupon_ids: [],
      payment_type: 'payment_type_card'
    }
  },
  mounted() {
    // form token
    if (!this.checkFormToken('Shopping')) return

    this.fetchData()
  },
  methods: {
    pointPercent(article) {
      return article.add_points * 100 / article.price
    },

    useCouponClicked(coupon, use_coupon) {
      coupon.use_coupon = use_coupon
      this.calcPayment()
    },

    useCoupons() {
      let coupons = []
      for (let i = 0; i < this.fetch_data.usable_coupons.length; i++) {
        if (this.fetch_data.usable_coupons[i].use_coupon) coupons.push(this.fetch_data.usable_coupons[i])
      }
      return coupons
    },

    usePointClicked() {
      this.calcPayment()
    },

    calcPayment() {
      this.calc_total_price = this.fetch_data.total_price + this.fetch_data.postage_price
      this.calc_total_coupon = this.calcCoupon()
      this.calc_total_price -= this.calc_total_coupon
      this.calc_usable_points = Math.min(this.usable_points, this.calc_total_price)
      if (this.use_points > this.calc_usable_points) {
        this.use_points = this.calc_usable_points
      }
      this.calc_use_points = this.use_points
      this.calc_total_price -= this.calc_use_points
    },

    calcCoupon() {
      let total_coupon = 0
      const use_coupons = this.useCoupons()
      this.use_coupon_ids = []
      for (let i = 0; i < use_coupons.length; i++) {
        const coupon = use_coupons[i]
        for (let j = 0; j < this.fetch_data.shopping.articles.length; j++) {
          let article = this.fetch_data.shopping.articles[j]
          article.coupon_id = null
          if (coupon.object_id === article.article.id) {
            coupon.count = article.count
            article.coupon_id = coupon.id
            this.use_coupon_ids.push(coupon.id)
            total_coupon += (coupon.price * article.count)
            break
          }
        }
      }
      return total_coupon
    },

    fetchData() {
      this.startAjax()
      this.axios
          .get(`${this.env.api_base_url}shop/shopping.json`)
          .then(response => {
            for (let i = 0; i < response.data.usable_coupons.length; i++) {
              response.data.usable_coupons[i].use_coupon = true
            }
            this.fetch_data = response.data
            this.shopping = response.data.shopping
            this.session = response.data.million_session

            this.usable_points = Math.min(this.fetch_data.usable_points, this.fetch_data.total_points)
            this.calcPayment()
            if (this.fetch_data.shopping_count === 0) {
              this.$router.push({name: 'ShoppingArticles'})
            }
          })
          .catch((response) => {
            this.errorAjax(response)
          })
          .finally(() => {
            this.finishAjax()
          })
    },

    paymentClicked() {
      if (this.request_exec) return
      this.request_exec = true

      this.startUpload()
      this.axios
          .post(`${this.env.api_base_url}shop/payment.json`, {
            use_points: this.calc_use_points,
            total_price: this.calc_total_price,
            coupon_ids: this.use_coupon_ids.join(','),
            payment_type: this.payment_type
          })
          .then((response) => {
            //console.log(response)
            document.location.href = response.data.url
          })
          .catch((response) => {
            this.errorAjax(response)
          })
          .finally(() => {
            this.finishUpload()
            this.request_exec = false
          })
    }
  }
}
</script>
